/* eslint-disable */
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, CircularProgress, Dialog, DialogContent, Divider, Grid, Icon } from "@mui/material";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { ResponseAlert } from "components/ResponseAlert";
import { MemberFriends } from "api/member";
import DataTable from "examples/Tables/DataTable";
import { formatter } from "utils/validations";
import SuiInput from "components/SuiInput";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { th } from "date-fns/locale";
import { format, subHours, addDays, startOfDay } from "date-fns";
import SuiButton from "components/SuiButton";

const columns = [
  { Header: "#", accessor: "no" },
  { Header: "ยูสเซอร์", accessor: "username" },
  // { Header: "ชื่อ-สกุล", accessor: "full_name" },
  // { Header: "เบอร์โทร", accessor: "phone_number" },
  { Header: "ฝาก", accessor: "sum_deposit" },
  { Header: "ถอน", accessor: "sum_withdraw" },
  { Header: "กำไร/ขาดทุน", accessor: "summary" },
];

function MemberFriendPopup({ member, onClose }) {
  //   const [memberData, setMemberData] = useState();
  //   const [isLoading, setLoading] = useState(false);
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [totalWithdraw, setTotalWithdraw] = useState(0);
  const [totalSummary, setTotalSummary] = useState(0);
  const [isPicker, setPicker] = useState(false);
  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [isLoading, setLoading] = useState(false);

  const [friendCount, setCount] = useState();

  const [friendTableData, setFriendTableData] = useState({ columns, rows: [] });
  const isOpen = true;

  function handleSelectDateRange(v) {
    console.log("member-filter -> handleSelectDateRange - v", v);
    setStartDate(format(new Date(v?.selection?.startDate), "yyyy-MM-dd"));
    setEndDate(format(new Date(v?.selection?.endDate), "yyyy-MM-dd"));
  }

  function handleLoad() {
    setPicker(false);
    getFriendRef();
  }

  async function getFriendRef() {
    const createdAt = {};
    if (startDate) {
      createdAt.from = format(subHours(startOfDay(new Date(startDate)), 7), "yyyy-MM-dd HH:mm:ss");
    }
    if (endDate) {
      createdAt.to = format(
        subHours(startOfDay(addDays(new Date(endDate), 1)), 7),
        "yyyy-MM-dd HH:mm:ss"
      );
    }
    setLoading(true);
    setFriendTableData({ columns, rows: [] });
    try {
      const response = await MemberFriends({
        member_uid: member?.member_uid,
        createdAt: createdAt,
      });
      setLoading(false);
      if (response?.data?.error === 0) {
        let tDeposit = 0;
        let tWithdraw = 0;
        const friends = response?.data?.data?.result;
        setCount(friends.length);
        const newFriends =
          friends &&
          friends.length > 0 &&
          friends.reduce((a, b, i) => {
            if (b?.username) {
              const news = { ...b };
              const fDeposit = b?.sum_deposit ? parseFloat(b?.sum_deposit) : 0;
              const fWithdraw = b?.sum_withdraw ? parseFloat(b?.sum_withdraw) : 0;
              tDeposit += fDeposit;
              tWithdraw += fWithdraw;

              news.no = i + 1;
              // news.full_name = (
              //   <SuiTypography component="label" variant="caption">
              //     {b?.first_name} {b?.last_name}
              //   </SuiTypography>
              // );
              news.sum_deposit = (
                <SuiTypography component="label" variant="caption" textColor="success">
                  {formatter.format(b?.sum_deposit).toString()}
                </SuiTypography>
              );
              news.sum_withdraw = (
                <SuiTypography component="label" variant="caption" textColor="error">
                  {formatter.format(b?.sum_withdraw).toString()}
                </SuiTypography>
              );
              const summary = fDeposit - fWithdraw;

              let cColor = "dark";

              if (summary > 0) {
                cColor = "success";
              }
              if (summary < 0) {
                cColor = "error";
              }
              news.summary = (
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textColor={cColor}
                >
                  {formatter.format(summary).toString()}
                </SuiTypography>
              );

              a.push(news);
            }
            return a;
          }, []);
        setTotalDeposit(tDeposit);
        setTotalWithdraw(tWithdraw);
        const summ = tDeposit - tWithdraw;
        setTotalSummary(summ);
        setFriendTableData({ columns, rows: newFriends });

        return;
      }
    } catch (err) {
      setLoading(false);
      ResponseAlert(err?.response);
    }
  }

  useEffect(() => {
    getFriendRef();
  }, []);

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      PaperProps={{
        style: {
          backgroundColor: "rgba(255, 255, 255, 0)",
          boxShadow: "none",
        },
      }}
    >
      <SuiBox display="flex" widht="100%">
        <Card>
          <SuiBox
            p={2}
            display="flex"
            backgroundColor="dark"
            backgroundGradient
            alignItems="center"
            justifyContent="space-between"
          >
            <SuiTypography component="label" variant="h6" fontWeight="bold" textColor="white">
              เพื่อน {friendCount} คน
            </SuiTypography>
            <SuiBox onClick={() => onClose()} sx={{ cursor: "pointer" }}>
              <Icon color="white">close</Icon>
            </SuiBox>
          </SuiBox>
          <SuiBox>
            {isPicker && (
              <SuiBox
                position="absolute"
                customClass="object-cover object-center"
                zIndex={99}
                mt={3}
                ml={3}
                borderRadius="50"
                backgroundColor="white"
              >
                <DateRangePicker
                  locale={th}
                  ranges={[
                    {
                      startDate: new Date(startDate),
                      endDate: new Date(endDate),
                      key: "selection",
                    },
                  ]}
                  onChange={handleSelectDateRange}
                />
                <SuiBox display="flex" justifyContent="flex-end" backgroundColor="white" p={2}>
                  <SuiButton
                    variant="gradient"
                    buttonColor="info"
                    size="small"
                    onClick={() => handleLoad()}
                  >
                    ตกลง
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            )}
            <SuiBox onClick={() => setPicker(true)} p={2}>
              <SuiInput
                value={`${format(new Date(startDate), "dd-MM-yyyy", {
                  locale: th,
                })} ถึง ${format(new Date(endDate), "dd-MM-yyyy", { locale: th })}`}
                disabled={true}
              />
            </SuiBox>
          </SuiBox>
          <SuiBox>
            {isLoading ? (
              <SuiBox display="flex" justifyContent="center" alignItems="center" p={2}>
                <CircularProgress size="2rem" />
              </SuiBox>
            ) : (
              <SuiBox display="flex" flexDirection="column">
                <SuiBox mt={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <SuiBox display="flex" textAlign="center" flexDirection="column">
                        <SuiTypography component="label" variant="caption">
                          ฝาก
                        </SuiTypography>
                        <SuiTypography component="span" variant="body2" textColor="success">
                          {totalDeposit ? formatter.format(totalDeposit).toString() : 0}
                        </SuiTypography>
                      </SuiBox>
                    </Grid>
                    <Grid item xs={4}>
                      <SuiBox display="flex" textAlign="center" flexDirection="column">
                        <SuiTypography component="label" variant="caption">
                          ถอน
                        </SuiTypography>
                        <SuiTypography component="span" variant="body2" textColor="error">
                          {totalWithdraw ? formatter.format(totalWithdraw).toString() : 0}
                        </SuiTypography>
                      </SuiBox>
                    </Grid>
                    <Grid item xs={4}>
                      <SuiBox display="flex" textAlign="center" flexDirection="column">
                        <SuiTypography component="label" variant="caption">
                          กำไร/ขาดทุน
                        </SuiTypography>
                        <SuiTypography
                          component="span"
                          variant="body2"
                          textColor={Number(totalSummary) >= 0 ? "success" : "error"}
                        >
                          {totalSummary ? formatter.format(totalSummary).toString() : 0}
                        </SuiTypography>
                      </SuiBox>
                    </Grid>
                  </Grid>
                </SuiBox>
                <Divider />
              </SuiBox>
            )}
            <SuiBox>
              <DialogContent>
                <DataTable
                  table={friendTableData}
                  canSearch
                  entriesPerPage={{
                    defaultValue: 10,
                    entries: [10, 20, 50, 100],
                  }}
                  noEndBorder
                />
              </DialogContent>
            </SuiBox>
          </SuiBox>
        </Card>
      </SuiBox>
    </Dialog>
  );
}

MemberFriendPopup.defaultProps = {
  member: {},
  onClose: () => {},
};

MemberFriendPopup.propTypes = {
  member: PropTypes.shape,
  onClose: PropTypes.func,
};

export default MemberFriendPopup;
